import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { RedirectPortaleComponent } from './@shared/redirect-portale/redirect-portale.component';
import { AppRoutingGuard } from './app-routing.guard';
import { AuthenticationGuard } from './auth';
import { HomeComponent } from './microservizi/home/home.component';
import { SceltaRuoloGuard } from './microservizi/fse/scelta-ruolo/scelta-ruolo.guard';
import { PatientStatusGuard } from './microservizi/screening/patient-status.guard';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotFoundModule } from './not-found/not-found.module';
import { Shell } from './shell/shell.service';
import { ConsensoGuard } from './microservizi/area-personale/gestisci-consensi/consensi-fse/consenso.guard';
import { ConsensoScreeningGuard } from './microservizi/area-personale/gestisci-consensi/consensi-screening/consenso-screening.guard';
import { SceltaTipologiaLoginGuard } from './auth/scelta-tipologia-login/scelta-tipologia-login.guard';

const routes: Routes = [
  {
    path: 'scelta-autenticazione',
    children: [
      {
        path: '',
        data: {
          title: marker('Scelta Autenticazione'),
          breadcrumb: { label: 'Scelta Autenticazione' },
        },
        loadChildren: () =>
          import('./auth/scelta-tipologia-login/scelta-tipologia-login.module').then(
            (m) => m.SceltaTipologiaLoginModule
          ),
      },
      {
        path: 'accesso-facilitato',
        data: {
          title: marker('Accesso  Facilitato'),
          breadcrumb: { label: 'Accesso  Facilitato' },
        },
        loadChildren: () =>
          import('./auth/accesso-facilitato/accesso-facilitato.module').then((m) => m.AccessoFacilitatoModule),
      },
    ],
  },
  {
    path: 'fascicolo/cda',
    canLoad: [AppRoutingGuard],
    loadChildren: () => import('./microservizi/fse/cda/cda.module').then((m) => m.CdaModule),
  },
  //home
  Shell.childRoutes([
    {
      path: 'home',
      // canLoad: [AppRoutingGuard],
      children: [
        {
          path: '',
          data: {
            title: marker('Home'),
            breadcrumb: { label: 'Home' },
          },
          // canActivate: [AuthenticationGuard],
          loadChildren: () => import('./microservizi/home/home.module').then((m) => m.HomeModule),
        },
      ],
    },
    //area-personale
    {
      path: 'area-personale',
      canLoad: [AppRoutingGuard],
      canActivate: [AppRoutingGuard],
      children: [
        {
          path: '',
          component: HomeComponent,
          data: {
            title: marker('Area personale'),
            breadcrumb: { label: 'Area personale' },
            pathPortale: '/servizi-agli-assistiti/',
          },
          canLoad: [AppRoutingGuard],
        },
        {
          path: 'profilo',
          canActivate: [AuthenticationGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/area-personale/profilo/profilo.module').then((m) => m.ProfiloModule),
        },
        {
          path: 'taccuino',
          canActivate: [AuthenticationGuard, ConsensoGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/area-personale/taccuino/taccuino.module').then((m) => m.TaccuinoModule),
        },
        {
          path: 'gestisci-consensi',
          canActivate: [SceltaRuoloGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/area-personale/gestisci-consensi/gestisci-consensi.module').then(
              (m) => m.GestisciConsensiModule
            ),
        },
        {
          path: 'gestisci-deleghe',
          canActivate: [ConsensoGuard, SceltaRuoloGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/area-personale/gestisci-deleghe/gestisci-deleghe.module').then(
              (m) => m.GestisciDelegheModule
            ),
        },
        {
          path: 'accessi-fse',
          canActivate: [ConsensoGuard, SceltaRuoloGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/fse/accessi-fse/accessi-fse.module').then((m) => m.AccessiFseModule),
        },
        {
          path: 'notifiche-fse-ini',
          canActivate: [ConsensoGuard, SceltaRuoloGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/fse/notifiche-fse-ini/notifiche-fse-ini.module').then(
              (m) => m.NotificheFseIniModule
            ),
        },
      ],
    },
    //prenotazioni
    {
      path: 'prenotazioni',
      // canLoad: [AppRoutingGuard],
      children: [
        {
          path: '',
          canLoad: [AppRoutingGuard],
          component: HomeComponent,
          data: {
            title: marker('Visite ed esami'),
            breadcrumb: { label: 'Visite ed esami' },
            pathPortale: '/servizi-agli-assistiti/prenotazioni/',
          },
          // canLoad: [AppRoutingGuard],
        },
        {
          path: 'prestazioni-disponibilita',
          canLoad: [AppRoutingGuard],
          canActivate: [AuthenticationGuard],
          loadChildren: () =>
            import('./microservizi/prenotazioni/verifica-disponibilita/prestazioni-disponibilita.modules').then(
              (m) => m.PrestazioniDisponibilitaModule
            ),
        },
        {
          path: 'prenota-appuntamento',
          // canLoad: [AppRoutingGuard],
          canActivate: [SceltaTipologiaLoginGuard],
          loadChildren: () =>
            import('./microservizi/prenotazioni/prenota-visita/prenota-visita.modules').then(
              (m) => m.PrenotaVisitaModule
            ),
        },
        {
          path: 'i-miei-appuntamenti',
          loadChildren: () =>
            import('./microservizi/prenotazioni/le-mie-prenotazioni/le-mie-prenotazioni.modules').then(
              (m) => m.LeMiePrenotazioniModule
            ),
        },
        {
          path: 'prenota-tampone',
          component: RedirectPortaleComponent,
          data: {
            pathPortale:
              '/servizi-agli-assistiti/servizi-di-prevenzione/screening-oncologici/screening-oncologici-servizi/',
          },
          // canLoad: [AppRoutingGuard],
        },
        {
          path: 'disdici-appuntamento',
          // canLoad: [AppRoutingGuard],
          canActivate: [SceltaTipologiaLoginGuard],
          loadChildren: () =>
            import('./microservizi/prenotazioni/disdici-prenotazione/disdici-prenotazione.modules').then(
              (m) => m.DisdiciPrenotazioneModule
            ),
        },
      ],
    },
    //fascicolo
    {
      path: 'fascicolo',
      canLoad: [AppRoutingGuard],
      canActivate: [AppRoutingGuard],
      children: [
        {
          path: '',
          // canActivate: [SceltaRuoloGuard],
          component: HomeComponent,
          data: {
            title: marker('Documenti e referti'),
            breadcrumb: { label: 'Documenti e referti' },
            pathPortale: '/servizi-agli-assistiti/fascicolo-sanitario-elettronico/',
          },
          canLoad: [AppRoutingGuard],
        },
        {
          path: 'documenti-e-referti',
          canActivate: [ConsensoGuard, SceltaRuoloGuard],
          loadChildren: () =>
            import('./microservizi/fse/documenti-referti/documenti-referti.module').then(
              (m) => m.DocumentiRefertiModule
            ),
          canLoad: [AppRoutingGuard],
        },
        {
          path: 'ricette-dematerializzate',
          canActivate: [ConsensoGuard, SceltaRuoloGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/fse/ricette-dematerializzate/ricette-dematerializzate.module').then(
              (m) => m.RicetteDematerializzateModule
            ),
        },
        {
          path: 'scelta-ruolo',
          canActivate: [AuthenticationGuard],
          loadChildren: () =>
            import('./microservizi/fse/scelta-ruolo/scelta-ruolo.module').then((m) => m.SceltaRuoloModule),
        },
      ],
    },
    //screening
    {
      path: 'screening',
      canLoad: [AppRoutingGuard],
      canActivate: [AppRoutingGuard],
      children: [
        {
          path: '',
          // canActivate: [SceltaRuoloGuard],
          component: HomeComponent,
          data: {
            title: marker('Screening'),
            breadcrumb: { label: 'Screening oncologici' },
            pathPortale: '/servizi-agli-assistiti/servizi-di-prevenzione/',
          },
        },
        {
          path: 'appuntamenti-esiti',
          canActivate: [ConsensoScreeningGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/screening/appuntamenti-esiti/appuntamenti-esiti.module').then(
              (m) => m.AppuntamentiEsitiModule
            ),
        },
        {
          path: 'contatti-screening',
          canActivate: [ConsensoScreeningGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/screening/contatti-screening/contatti-screening.module').then(
              (m) => m.ContattiScreeningModule
            ),
        },
        {
          path: 'aderisci-campagna',
          canActivate: [PatientStatusGuard],
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/screening/aderisci-campagna/aderisci-campagna.module').then(
              (m) => m.AderisciCampagnaModule
            ),
        },
      ],
    },
    {
      path: 'per-la-mamma',
      canLoad: [AppRoutingGuard],
      canActivate: [AppRoutingGuard],
      children: [
        {
          path: '',
          component: HomeComponent,
          data: {
            title: marker('Per la mamma'),
            breadcrumb: { label: 'Per la mamma' },
            pathPortale: '/servizi-agli-assistiti/per-la-mamma',
          },
          canLoad: [AppRoutingGuard],
          // canActivate: [AuthenticationGuard],
        },
        {
          path: 'le-mie-gravidanze',
          canLoad: [AppRoutingGuard],
          canActivate: [AuthenticationGuard],
          loadChildren: () =>
            import('./microservizi/per-la-mamma/le-mie-gravidanze/le-mie-gravidanze.modules').then(
              (m) => m.LeMieGravidanzeModule
            ),
        },
        {
          path: 'diario-della-gravidanza',
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/per-la-mamma/diario-della-gravidanza/diario-della-gravidanza.modules').then(
              (m) => m.DiarioDellaGravidanzaModule
            ),
        },
        {
          path: 'enti-rosa',
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/per-la-mamma/enti-rosa/enti-rosa.modules').then((m) => m.EntiRosaModule),
        },
        {
          path: 'le-fasi-della-gravidanza',
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/per-la-mamma/fasi-della-gravidanza/fasi-della-gravidanza.modules').then(
              (m) => m.FasiDellaGravidanzaModule
            ),
        },
        {
          path: 'prestazioni-agevolazioni',
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/per-la-mamma/prestazioni-agevolazioni/prestazioni-agevolazioni.modules').then(
              (m) => m.PrestazioniAgevolazioniModule
            ),
        },
      ],
    },
    {
      path: 'cartella-clinica-vaccinale',
      canLoad: [AppRoutingGuard],
      canActivate: [AppRoutingGuard],
      children: [
        {
          path: '',
          component: HomeComponent,
          data: {
            title: marker('Cartella Vaccinale'),
            breadcrumb: { label: 'Cartella vaccinale' },
            pathPortale: '/servizi-agli-assistiti/servizi-di-prevenzione/le-mie-vaccinazioni/',
          },
          canLoad: [AppRoutingGuard],
          canActivate: [AuthenticationGuard],
        },
        {
          path: 'corredo-vaccinale',
          canLoad: [AppRoutingGuard],
          canActivate: [AuthenticationGuard],
          loadChildren: () =>
            import('./microservizi/cartella-vaccinale/corredo-vaccinale/corredo-vaccinale.module').then(
              (m) => m.CorredoVaccinaleModule
            ),
        },
        {
          path: 'preferenze-contatti',
          canLoad: [AppRoutingGuard],
          canActivate: [AuthenticationGuard],
          loadChildren: () =>
            import('./microservizi/cartella-vaccinale/preferenze-contatti/preferenze-contatto.module').then(
              (m) => m.PreferenzeContattoModule
            ),
        },
        {
          path: 'punti-vaccinali',
          canLoad: [AppRoutingGuard],
          canActivate: [AuthenticationGuard],
          loadChildren: () =>
            import('./microservizi/cartella-vaccinale/punti-vaccinali/punti-vaccinali.module').then(
              (m) => m.PuntiVaccinaliModule
            ),
        },
      ],
    },
    {
      path: 'utilita',
      canLoad: [AppRoutingGuard],
      canActivate: [AppRoutingGuard],
      children: [
        {
          path: '',
          component: HomeComponent,
          data: {
            title: marker('Utilità'),
            breadcrumb: { label: 'Utilità' },
            pathPortale: '/servizi-agli-assistiti/',
          },
          canLoad: [AppRoutingGuard],
        },
        {
          path: 'cerca-ente',
          canLoad: [AppRoutingGuard],
          loadChildren: () =>
            import('./microservizi/utilita/cerca-ente/cerca-ente.module').then((m) => m.CercaEnteModule),
        },
      ],
    },
  ]),
  // Fallback when no prior route is matched
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      title: marker('Errore 404 - Pagina non trovata'),
      breadcrumb: 'Errore 404 - Pagina non trovata',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollOffset: [0, 200],
    }),
    NotFoundModule,
  ],
  exports: [RouterModule],
  providers: [AppRoutingGuard],
})
export class AppRoutingModule {}
