<div class="sidebar-joke" [ngClass]="{ expandedJoke: isExpanded }"></div>
<div class="float-toogle d-md-none text-center position-fixed bg-white">
  <a role="button" (click)="handleSidebarToggle()">
    <i class="bi bi-list icona-md" title="Apri Menu"></i>
  </a>
</div>
<div class="sidebar vh-100 bg-white position-fixed overflow-auto" [ngClass]="{ expanded: isExpanded }">
  <div class="header-sidebar d-flex align-items-center">
    <a role="button" (click)="handleSidebarToggle()">
      <i *ngIf="isExpanded" class="bi bi-x-lg icona-sm" title="Chiudi Menu"></i>
      <i *ngIf="!isExpanded" class="bi bi-arrow-right icona-md" title="Apri Menu"></i>
    </a>
  </div>
  <div class="content-sidebar" [ngClass]="{ 'ps-2': isExpanded }">
    <div *ngIf="delegante$ | async" class="sidebar-menu" (click)="close()">
      <div class="sidebar-item box-torna">
        <a role="button" (click)="tornaSuFse()" class="justify-content-center">
          <i *ngIf="!isExpanded" class="bi bi-box-arrow-left icon-sm icona-tornafse" title="Torna al tuo FSE"></i>
          <span class="text-item text-end">Torna al TUO <br />Fascicolo Sanitario Elettronico</span>
        </a>
      </div>
    </div>

    <!-- AREA PERSONALE -->
    <div *ngIf="isExpanded">
      <button
        class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
        [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
        type="button"
        (click)="isAreaPersonaleCollapsed = !isAreaPersonaleCollapsed"
      >
        <h1>Area personale</h1>
        <i
          *ngIf="isAreaPersonaleCollapsed"
          class="bi bi-chevron-down icona-sm icona-secondary"
          [ngClass]="isExpanded ? 'me-2' : ''"
          [title]="isExpanded ? 'Apri Menu Area personale' : ''"
          placement="end"
          container="body"
          ngbTooltip="Area personale"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        ></i>
        <i
          *ngIf="!isAreaPersonaleCollapsed"
          class="bi bi-chevron-up icona-sm icona-secondary"
          [ngClass]="isExpanded ? 'me-2' : ''"
          [title]="isExpanded ? 'Chiudi Menu Area personale' : ''"
          placement="end"
          container="body"
          ngbTooltip="Area personale"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        ></i>
      </button>
      <hr class="hr-2" [ngClass]="isAreaPersonaleCollapsed ? 'mb-3' : ''" />
      <div [ngbCollapse]="isAreaPersonaleCollapsed" class="dont-collapse-xl">
        <ul class="sidebar-menu" (click)="close()">
          <li class="sidebar-item even" routerLinkActive="active" *ngIf="(delegante$ | async) === null">
            <a routerLink="/area-personale/profilo" role="button">
              <i
                class="bi bi-person icona-sm"
                [title]="isExpanded ? 'Profilo' : ''"
                placement="end"
                container="body"
                ngbTooltip="Profilo"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Profilo</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active" *ngIf="(delegante$ | async) === null">
            <a routerLink="/area-personale/taccuino" role="button">
              <i
                class="bi bi-journal-bookmark icona-sm"
                [title]="isExpanded ? 'Taccuino' : ''"
                placement="end"
                container="body"
                ngbTooltip="Taccuino"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Taccuino</span>
            </a>
          </li>
          <li
            class="sezioni-taccuino col-9 text-break overflow-wrap ps-3 py-2 text-start"
            routerLinkActive="active"
            *ngIf="router.url.includes('/area-personale/taccuino') && (delegante$ | async) === null"
          >
            <a routerLink="/area-personale/taccuino/rilevazioni-parametri-osservabili" role="button">
              <button class="border-0 bg-white text-start item-taccuino">Rilevazioni parametri osservabili</button>
            </a>
          </li>
          <li
            class="sezioni-taccuino col-9 text-break overflow-wrap ps-3 py-2 text-start"
            routerLinkActive="active"
            *ngIf="router.url.includes('/area-personale/taccuino')"
          >
            <a routerLink="/area-personale/taccuino/eventi" role="button">
              <button class="border-0 bg-white text-start item-taccuino">Eventi</button>
            </a>
          </li>
          <!-- <li
            class="sezioni-taccuino col-9 text-break overflow-wrap ps-3 py-2 text-start"
            routerLinkActive="active"
            *ngIf="router.url.includes('/area-personale/taccuino')"
          >
            <a routerLink="/area-personale/taccuino/prodotti-farmaceutici-assunti" role="button">
              <button class="border-0 bg-white text-start item-taccuino">Farmaci e integratori</button>
            </a>
          </li> -->
          <!-- <li
            class="sezioni-taccuino col-9 text-break overflow-wrap ps-3 py-2 mb-2 text-start"
            routerLinkActive="active"
            *ngIf="router.url.includes('/area-personale/taccuino')"
          >
            <a routerLink="/area-personale/taccuino/segni-e-sintomi" role="button">
              <button class="border-0 bg-white text-start item-taccuino">Segni e sintomi</button>
            </a>
          </li> -->
          <li
            class="sezioni-taccuino col-9 text-break overflow-wrap ps-3 py-2 text-start"
            routerLinkActive="active"
            *ngIf="router.url.includes('/area-personale/taccuino')"
          >
            <a routerLink="/area-personale/taccuino/viaggi-estero" role="button">
              <button class="border-0 bg-white text-start item-taccuino">Viaggi all'estero</button>
            </a>
          </li>
          <li
            class="sezioni-taccuino col-9 text-break overflow-wrap ps-3 py-2 mb-2 text-start"
            routerLinkActive="active"
            *ngIf="router.url.includes('/area-personale/taccuino')"
          >
            <a routerLink="/area-personale/taccuino/dispositivi-medici" role="button">
              <button class="border-0 bg-white text-start item-taccuino">Protesi, impianti o ausili</button>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/area-personale/gestisci-consensi" role="button">
              <i
                class="bi bi-file-earmark-lock2 icona-sm"
                [title]="isExpanded ? 'Gestisci consensi' : ''"
                placement="end"
                container="body"
                ngbTooltip="Gestisci consensi"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Gestisci consensi</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/area-personale/gestisci-deleghe" role="button">
              <i
                class="bi bi-people icona-sm"
                [title]="isExpanded ? 'Gestisci deleghe' : ''"
                placement="end"
                container="body"
                ngbTooltip="Gestisci deleghe"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Gestisci deleghe</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/area-personale/accessi-fse" role="button">
              <i
                class="bi bi-box-arrow-in-right icona-sm"
                [title]="isExpanded ? 'Accessi al tuo FSE' : ''"
                placement="end"
                container="body"
                ngbTooltip="Accessi al tuo FSE"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Accessi al tuo FSE</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/area-personale/notifiche-fse-ini" role="button">
              <i
                class="bi bi-bell icona-sm"
                [title]="isExpanded ? 'Notifiche FSE-INI' : ''"
                placement="end"
                container="body"
                ngbTooltip="Notifiche FSE-INI"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Notifiche FSE-INI</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="sidebar-item even notExpandedRounded my-3"
      routerLinkActive="active"
      ngbDropdown
      container="body"
      placement="end-top"
      *ngIf="!isExpanded"
    >
      <a
        role="button"
        ngbDropdownToggle
        placement="end"
        container="body"
        ngbTooltip="Area personale"
        tooltipClass="tooltip-sidebar"
        [disableTooltip]="isExpanded"
      >
        <i class="bi bi-person icona-sm icona-primary"></i>
      </a>
      <div ngbDropdownMenu class="drop-menu">
        <a routerLink="/area-personale" role="button">
          <button ngbDropdownItem class="drop-item text-primary fw-bolder">Area personale</button>
        </a>
        <hr class="my-1" />
        <a routerLink="/area-personale/profilo" role="button" *ngIf="(delegante$ | async) === null">
          <button ngbDropdownItem class="drop-item text-primary">Profilo</button>
        </a>
        <a routerLink="/area-personale/taccuino" role="button" *ngIf="(delegante$ | async) === null">
          <button ngbDropdownItem class="drop-item text-primary">Taccuino</button>
        </a>
        <a routerLink="/area-personale/gestisci-consensi" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Gestisci consensi</button>
        </a>
        <a routerLink="/area-personale/gestisci-deleghe" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Gestisci deleghe</button>
        </a>
        <a routerLink="/area-personale/accessi-fse" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Accessi al tuo FSE</button>
        </a>
        <a routerLink="/area-personale/notifiche-fse-ini" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Notifiche FSE-INI</button>
        </a>
      </div>
    </div>

    <!-- PRENOTAZIONI -->
    <div *ngIf="isExpanded && isPrenotazioni && (delegante$ | async) === null">
      <button
        class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
        [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
        type="button"
        (click)="isPrenotazioniCollapsed = !isPrenotazioniCollapsed"
      >
        <h1>Visite ed esami</h1>
        <i
          *ngIf="isPrenotazioniCollapsed"
          class="bi bi-chevron-down icona-sm icona-secondary"
          [ngClass]="isExpanded ? 'me-2' : ''"
          [title]="isExpanded ? 'Apri Menu Visite ed esami' : ''"
          placement="end"
          container="body"
          ngbTooltip="Visite ed esami"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        ></i>
        <i
          *ngIf="!isPrenotazioniCollapsed"
          class="bi bi-chevron-up icona-sm icona-secondary"
          [ngClass]="isExpanded ? 'me-2' : ''"
          [title]="isExpanded ? 'Chiudi Menu Visite ed esami' : ''"
          placement="end"
          container="body"
          ngbTooltip="Visite ed esami"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        ></i>
      </button>
      <hr class="hr-2" [ngClass]="isPrenotazioniCollapsed ? 'mb-3' : ''" />
      <div [ngbCollapse]="isPrenotazioniCollapsed" class="dont-collapse-xl">
        <ul class="sidebar-menu" (click)="close()">
          <li class="sidebar-item even" routerLinkActive="active" hidden="true">
            <a routerLink="/prenotazioni/prestazioni-disponibilita" role="button">
              <i
                class="bi bi-calendar2-heart icona-sm"
                [title]="isExpanded ? 'Prestazioni e Disponibilità' : ''"
                placement="end"
                container="body"
                ngbTooltip="Prestazioni e Disponibilità"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Prestazioni e Disponibilità</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/prenotazioni/i-miei-appuntamenti" role="button">
              <i
                class="bi bi-calendar-week icona-sm"
                [title]="isExpanded ? 'I miei appuntamenti' : ''"
                placement="end"
                container="body"
                ngbTooltip="I miei appuntamenti"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">I miei appuntamenti</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/prenotazioni/prenota-appuntamento" role="button">
              <i
                class="bi bi-calendar-plus icona-sm"
                [title]="isExpanded ? 'Prenota appuntamento' : ''"
                placement="end"
                container="body"
                ngbTooltip="Prenota appuntamento"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Prenota appuntamento</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/prenotazioni/disdici-appuntamento" role="button">
              <i
                class="bi bi-calendar-x icona-sm"
                [title]="isExpanded ? 'Disdici appuntamento' : ''"
                placement="end"
                container="body"
                ngbTooltip="Disdici appuntamento"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Disdici appuntamento</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="sidebar-item odd notExpandedRounded"
      routerLinkActive="active"
      ngbDropdown
      container="body"
      placement="end-top"
      *ngIf="isPrenotazioni && !isExpanded && (delegante$ | async) === null"
    >
      <a
        role="button"
        ngbDropdownToggle
        placement="end"
        container="body"
        ngbTooltip="Visite ed esami"
        tooltipClass="tooltip-sidebar"
        [disableTooltip]="isExpanded"
      >
        <i class="bi bi-calendar-week icona-sm icona-secondary"></i>
      </a>
      <div ngbDropdownMenu class="drop-menu">
        <a routerLink="/prenotazioni" role="button">
          <button ngbDropdownItem class="drop-item text-primary fw-bolder">Visite ed esami</button>
        </a>
        <hr class="my-1" />
        <a routerLink="/prenotazioni/i-miei-appuntamenti" role="button">
          <button ngbDropdownItem class="drop-item text-primary">I miei appuntamenti</button>
        </a>
        <a routerLink="/prenotazioni/prenota-appuntamento" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Prenota appuntamento</button>
        </a>
        <a routerLink="/prenotazioni/disdici-appuntamento" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Disdici appuntamento</button>
        </a>
      </div>
    </div>

    <!-- FASCICOLO SANITARIO -->
    <div *ngIf="isFSE && isExpanded">
      <button
        class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
        [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
        type="button"
        (click)="isFseCollapsed = !isFseCollapsed"
      >
        <h1>Documenti e referti</h1>
        <i
          *ngIf="isFseCollapsed"
          class="bi bi-chevron-down icona-sm icona-secondary"
          [ngClass]="isExpanded ? 'me-2' : ''"
          [title]="isExpanded ? 'Apri Menu Documenti e referti' : ''"
          placement="end"
          container="body"
          ngbTooltip="Documenti e referti"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        ></i>
        <i
          *ngIf="!isFseCollapsed"
          class="bi bi-chevron-up icona-sm icona-secondary"
          [ngClass]="isExpanded ? 'me-2' : ''"
          [title]="isExpanded ? 'Chiudi Menu Documenti e referti' : ''"
          placement="end"
          container="body"
          ngbTooltip="Documenti e referti"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        ></i>
      </button>
      <hr class="hr-2" [ngClass]="isFseCollapsed ? 'mb-3' : ''" />
      <div [ngbCollapse]="isFseCollapsed" class="dont-collapse-xl">
        <ul class="sidebar-menu" (click)="close()">
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/fascicolo/documenti-e-referti" role="button">
              <i
                class="bi bi-file-earmark-text icona-sm"
                [title]="isExpanded ? 'Documenti e Referti' : ''"
                placement="end"
                container="body"
                ngbTooltip="Documenti e Referti"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">I miei documenti</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/fascicolo/ricette-dematerializzate" role="button">
              <i
                class="bi bi-postcard icona-sm"
                [title]="isExpanded ? 'Ricette dematerializzate' : ''"
                placement="end"
                container="body"
                ngbTooltip="Ricette dematerializzate"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Le mie ricette</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div
      class="sidebar-item even notExpandedRounded my-3"
      routerLinkActive="active"
      ngbDropdown
      container="body"
      placement="end-top"
      *ngIf="isFSE && !isExpanded"
    >
      <a
        role="button"
        ngbDropdownToggle
        placement="end"
        container="body"
        ngbTooltip="Documenti e referti"
        tooltipClass="tooltip-sidebar"
        [disableTooltip]="isExpanded"
      >
        <i class="bi bi-file-earmark-text icona-sm icona-primary"></i>
      </a>
      <div ngbDropdownMenu class="drop-menu">
        <a routerLink="/fascicolo" role="button">
          <button ngbDropdownItem class="drop-item text-primary fw-bolder">Documenti e referti</button>
        </a>
        <hr class="my-1" />
        <a routerLink="/fascicolo/documenti-e-referti" role="button">
          <button ngbDropdownItem class="drop-item text-primary">I miei documenti</button>
        </a>
        <a routerLink="/fascicolo/ricette-dematerializzate" role="button">
          <button ngbDropdownItem class="drop-item text-primary">Le mie ricette</button>
        </a>
      </div>
    </div>
    <section *ngIf="(delegante$ | async) === null">
      <!-- SCREENING -->
      <div *ngIf="isScreening && isExpanded">
        <button
          class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
          [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
          type="button"
          (click)="isScreeningCollapsed = !isScreeningCollapsed"
        >
          <h1>Screening oncologici</h1>
          <i
            *ngIf="isScreeningCollapsed"
            class="bi bi-chevron-down icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Apri Menu Screening oncologici' : ''"
            placement="end"
            container="body"
            ngbTooltip="Screening oncologici"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
          <i
            *ngIf="!isScreeningCollapsed"
            class="bi bi-chevron-up icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Chiudi Menu Screening oncologici' : ''"
            placement="end"
            container="body"
            ngbTooltip="Screening oncologici"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
        </button>
        <hr class="hr-2" [ngClass]="isScreeningCollapsed ? 'mb-3' : ''" />
        <div [ngbCollapse]="isScreeningCollapsed" class="dont-collapse-xl">
          <ul class="sidebar-menu" (click)="close()">
            <li class="sidebar-item even" routerLinkActive="active">
              <a routerLink="/screening/appuntamenti-esiti" role="button">
                <i
                  class="ci icon-icona-menu-appuntamenti-screening"
                  [title]="isExpanded ? 'Appuntamenti ed esiti' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Appuntamenti ed esiti"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Appuntamenti ed esiti</span>
              </a>
            </li>
            <li class="sidebar-item odd" routerLinkActive="active">
              <a routerLink="/screening/contatti-screening" role="button">
                <i
                  class="ci icon-icona-menu-contatti-screening"
                  [title]="isExpanded ? 'Contatti screening' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Contatti screening"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Contatti screening</span>
              </a>
            </li>
            <li class="sidebar-item even" routerLinkActive="active">
              <a routerLink="/screening/aderisci-campagna" role="button">
                <i
                  class="ci icon-icona-menu-campagna-screening"
                  [title]="isExpanded ? 'Aderisci alla campagna' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Aderisci alla campagna"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Aderisci alla campagna</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="sidebar-item odd notExpandedRounded my-3"
        routerLinkActive="active"
        ngbDropdown
        container="body"
        placement="end-top"
        *ngIf="isScreening && !isExpanded"
      >
        <a
          role="button"
          ngbDropdownToggle
          placement="end"
          container="body"
          ngbTooltip="Screening oncologici"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        >
          <i class="ci icon-icona-menu-contatti-screening icona-sm icona-secondary"></i>
        </a>
        <div ngbDropdownMenu class="drop-menu">
          <a routerLink="/screening" role="button">
            <button ngbDropdownItem class="drop-item text-primary fw-bolder">Screening oncologici</button>
          </a>
          <hr class="my-1" />
          <a routerLink="/screening/appuntamenti-esiti" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Appuntamenti ed esiti</button>
          </a>
          <a routerLink="/screening/contatti-screening" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Contatti screening</button>
          </a>
          <a routerLink="/screening/aderisci-campagna" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Aderisci alla campagna</button>
          </a>
        </div>
      </div>

      <!-- PER LA MAMMA -->
      <div *ngIf="isPerLaMamma && isExpanded">
        <button
          class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
          [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
          type="button"
          (click)="isPerLaMammaCollapsed = !isPerLaMammaCollapsed"
        >
          <h1>Per la mamma</h1>
          <i
            *ngIf="isPerLaMammaCollapsed"
            class="bi bi-chevron-down icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Apri Menu Per la mamma' : ''"
            placement="end"
            container="body"
            ngbTooltip="Per la mamma"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
          <i
            *ngIf="!isPerLaMammaCollapsed"
            class="bi bi-chevron-up icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Chiudi Menu Per la mamma' : ''"
            placement="end"
            container="body"
            ngbTooltip="Per la mamma"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
        </button>
        <hr class="hr-2" [ngClass]="isPerLaMammaCollapsed ? 'mb-3' : ''" />
        <div [ngbCollapse]="isPerLaMammaCollapsed" class="dont-collapse-xl">
          <ul class="sidebar-menu" (click)="close()">
            <li class="sidebar-item even le-mie-gravidanze" routerLinkActive="active">
              <a routerLink="/per-la-mamma/le-mie-gravidanze" role="button">
                <div class="px-2">
                  <img
                    class="img-baby"
                    [title]="isExpanded ? 'Le mie gravidanze' : ''"
                    alt="Le mie gravidanze"
                    placement="end"
                    container="body"
                    ngbTooltip="Le mie gravidanze"
                    tooltipClass="tooltip-sidebar"
                    [disableTooltip]="isExpanded"
                  />
                </div>
                <span class="text-item">Le mie gravidanze</span>
              </a>
            </li>
            <li class="sidebar-item odd" routerLinkActive="active">
              <a routerLink="/per-la-mamma/diario-della-gravidanza" role="button">
                <i
                  class="bi bi-journal-bookmark icona-sm"
                  [title]="isExpanded ? 'Diario della gravidanza' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Diario della gravidanza"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Diario della gravidanza</span>
              </a>
            </li>
            <li class="sidebar-item even le-fasi-della-gravidanza" routerLinkActive="active">
              <a routerLink="/per-la-mamma/le-fasi-della-gravidanza" role="button">
                <div class="px-2">
                  <img
                    class="img-mother"
                    [title]="isExpanded ? 'Le fasi della gravidanza' : ''"
                    alt="Le fasi della gravidanza"
                    placement="end"
                    container="body"
                    ngbTooltip="Le fasi della gravidanza"
                    tooltipClass="tooltip-sidebar"
                    [disableTooltip]="isExpanded"
                  />
                </div>
                <span class="text-item">Le fasi della gravidanza</span>
              </a>
            </li>
            <li class="sidebar-item odd" routerLinkActive="active">
              <a routerLink="/per-la-mamma/prestazioni-agevolazioni" role="button">
                <i
                  class="bi bi-cash-stack icona-sm icona-secondary"
                  [title]="isExpanded ? 'Prestazioni ed agevolazioni' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Prestazioni ed agevolazioni"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Prestazioni ed agevolazioni</span>
              </a>
            </li>
            <li class="sidebar-item even" routerLinkActive="active">
              <a href="/utilita/cerca-ente?tipologia=enti-rosa" role="button">
                <i
                  class="bi bi-hospital icona-sm"
                  [title]="isExpanded ? 'Enti rosa' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Enti rosa"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Enti rosa</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="sidebar-item even notExpandedRounded my-3 le-mie-gravidanze"
        routerLinkActive="active"
        ngbDropdown
        container="body"
        placement="end-top"
        *ngIf="isPerLaMamma && !isExpanded"
      >
        <a
          role="button"
          ngbDropdownToggle
          placement="end"
          container="body"
          ngbTooltip="Per la mamma"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        >
          <div class="px-2"><img class="img-baby" title="Per la mamma" alt="Per la mamma" /></div>
        </a>
        <div ngbDropdownMenu class="drop-menu">
          <a routerLink="/per-la-mamma" role="button">
            <button ngbDropdownItem class="drop-item text-primary fw-bolder">Per la mamma</button>
          </a>
          <hr class="my-1" />
          <a routerLink="/per-la-mamma/le-mie-gravidanze" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Le mie gravidanze</button>
          </a>
          <a routerLink="/per-la-mamma/diario-della-gravidanza" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Diario della gravidanza</button>
          </a>
          <a routerLink="/per-la-mamma/le-fasi-della-gravidanza" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Le fasi della gravidanza</button>
          </a>
          <a routerLink="/per-la-mamma/prestazioni-agevolazioni" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Prestazioni ed agevolazioni</button>
          </a>
          <a href="/utilita/cerca-ente?tipologia=enti-rosa" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Enti rosa</button>
          </a>
        </div>
      </div>

      <!-- VACCINAZIONI -->
      <div *ngIf="isVaccinazioni && isExpanded">
        <button
          class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
          [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
          type="button"
          (click)="isVaccinazioniCollapsed = !isVaccinazioniCollapsed"
        >
          <h1>Cartella vaccinale</h1>
          <i
            *ngIf="isVaccinazioniCollapsed"
            class="bi bi-chevron-down icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Apri Menu Cartella Vaccinale' : ''"
            placement="end"
            container="body"
            ngbTooltip="Cartella Vaccinale"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
          <i
            *ngIf="!isVaccinazioniCollapsed"
            class="bi bi-chevron-up icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Chiudi Menu Cartella Vaccinale' : ''"
            placement="end"
            container="body"
            ngbTooltip="Cartella Vaccinale"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
        </button>
        <hr class="hr-2" [ngClass]="isVaccinazioniCollapsed ? 'mb-3' : ''" />
        <div [ngbCollapse]="isVaccinazioniCollapsed" class="dont-collapse-xl">
          <ul class="sidebar-menu" (click)="close()">
            <li class="sidebar-item odd cartella-vaccinale" routerLinkActive="active">
              <a routerLink="/cartella-clinica-vaccinale/corredo-vaccinale" role="button">
                <div class="px-2">
                  <img
                    class="img-corredo-vaccinale"
                    [title]="isExpanded ? 'Corredo vaccinale' : ''"
                    alt="Corredo vaccinale"
                    placement="end"
                    container="body"
                    ngbTooltip="Corredo vaccinale"
                    tooltipClass="tooltip-sidebar"
                    [disableTooltip]="isExpanded"
                  />
                </div>
                <span class="text-item">Corredo vaccinale</span>
              </a>
            </li>
            <li class="sidebar-item even cartella-vaccinale" routerLinkActive="active">
              <a routerLink="/cartella-clinica-vaccinale/preferenze-contatti" role="button">
                <div class="px-2">
                  <img
                    class="preferenze-vacc"
                    alt="Preferenze e Contatti"
                    [title]="isExpanded ? 'Preferenze e Contatti' : ''"
                    alt="Preferenze e Contatti"
                    placement="end"
                    container="body"
                    ngbTooltip="Preferenze e Contatti"
                    tooltipClass="tooltip-sidebar"
                    [disableTooltip]="isExpanded"
                  />
                </div>
                <span class="text-item">Contatti</span>
              </a>
            </li>
            <li class="sidebar-item odd cartella-vaccinale" routerLinkActive="active">
              <a href="/utilita/cerca-ente?tipologia=centri-vaccinali" role="button">
                <div class="px-2">
                  <img
                    class="img-punti-vaccinali"
                    [title]="isExpanded ? 'Punti Vaccinali' : ''"
                    alt="Punti Vaccinali"
                    placement="end"
                    container="body"
                    ngbTooltip="Punti Vaccinali"
                    tooltipClass="tooltip-sidebar"
                    [disableTooltip]="isExpanded"
                  />
                </div>
                <span class="text-item">Punti Vaccinali</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="sidebar-item odd notExpandedRounded my-3 cartella-vaccinale"
        routerLinkActive="active"
        ngbDropdown
        container="body"
        placement="end-top"
        *ngIf="isVaccinazioni && !isExpanded"
      >
        <a
          role="button"
          ngbDropdownToggle
          placement="end"
          container="body"
          ngbTooltip="Cartella vaccinale"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        >
          <div class="px-2">
            <img class="img-corredo-vaccinale" title="Cartella vaccinale" alt="Cartella vaccinale" />
          </div>
        </a>
        <div ngbDropdownMenu class="drop-menu">
          <a routerLink="/cartella-clinica-vaccinale" role="button">
            <button ngbDropdownItem class="drop-item text-primary fw-bolder">Cartella vaccinale</button>
          </a>
          <hr class="my-1" />
          <a routerLink="/cartella-clinica-vaccinale/corredo-vaccinale" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Corredo vaccinale</button>
          </a>
          <a routerLink="/cartella-clinica-vaccinale/preferenze-contatti" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Contatti</button>
          </a>
          <a href="/utilita/cerca-ente?tipologia=centri-vaccinali" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Punti Vaccinali</button>
          </a>
        </div>
      </div>

      <!-- UTILITA -->
      <div *ngIf="isUtilita && isExpanded">
        <button
          class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
          [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
          type="button"
          (click)="isUtilityCollapsed = !isUtilityCollapsed"
        >
          <h1>Utilità</h1>
          <i
            *ngIf="isUtilityCollapsed"
            class="bi bi-chevron-down icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Apri Menu Utility' : ''"
            placement="end"
            container="body"
            ngbTooltip="Utility"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
          <i
            *ngIf="!isUtilityCollapsed"
            class="bi bi-chevron-up icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Chiudi Menu Utility' : ''"
            placement="end"
            container="body"
            ngbTooltip="Utility"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
        </button>
        <hr class="hr-2" [ngClass]="isUtilityCollapsed ? 'mb-3' : ''" />
        <div [ngbCollapse]="isUtilityCollapsed" class="dont-collapse-xl">
          <ul class="sidebar-menu">
            <li class="sidebar-item odd cerca-ente" routerLinkActive="active">
              <a routerLink="/utilita/cerca-ente" role="button">
                <div class="px-2">
                  <img
                    class="img-ente"
                    [title]="isExpanded ? 'Cerca ente' : ''"
                    alt="Cerca ente"
                    placement="end"
                    container="body"
                    ngbTooltip="Cerca ente"
                    tooltipClass="tooltip-sidebar"
                    [disableTooltip]="isExpanded"
                  />
                </div>
                <span class="text-item">Cerca Ente</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="sidebar-item odd notExpandedRounded my-3 cerca-ente"
        routerLinkActive="active"
        ngbDropdown
        container="body"
        placement="end-top"
        *ngIf="isUtilita && !isExpanded"
      >
        <a
          role="button"
          ngbDropdownToggle
          placement="end"
          container="body"
          ngbTooltip="Utility"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        >
          <div class="px-2">
            <img
              class="img-ente"
              [title]="isExpanded ? 'Cerca ente' : ''"
              alt="Cerca ente"
              placement="end"
              container="body"
              ngbTooltip="Cerca ente"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            />
          </div>
        </a>
        <div ngbDropdownMenu class="drop-menu">
          <a routerLink="/utilita" role="button">
            <button ngbDropdownItem class="drop-item text-primary fw-bolder">Utilità</button>
          </a>
          <hr class="my-1" />
          <a routerLink="/utilita/cerca-ente" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Cerca ente</button>
          </a>
        </div>
      </div>
      <!-- ANAGRAFE SCELTA MEDICO -->
      <div *ngIf="isExpanded">
        <button
          class="btn btn-collapse-filter w-100 p-0 d-flex align-items-center"
          [ngClass]="isExpanded ? 'justify-content-between' : 'justify-content-center'"
          type="button"
          (click)="isAnagrafeCollapsed = !isAnagrafeCollapsed"
        >
          <h1>Anagrafe</h1>
          <i
            *ngIf="isAnagrafeCollapsed"
            class="bi bi-chevron-down icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Apri Menu Anagrafe' : ''"
            placement="end"
            container="body"
            ngbTooltip="Anagrafe"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
          <i
            *ngIf="!isAnagrafeCollapsed"
            class="bi bi-chevron-up icona-sm icona-secondary"
            [ngClass]="isExpanded ? 'me-2' : ''"
            [title]="isExpanded ? 'Chiudi Menu Anagrafe' : ''"
            placement="end"
            container="body"
            ngbTooltip="Anagrafe"
            tooltipClass="tooltip-sidebar"
            [disableTooltip]="isExpanded"
          ></i>
        </button>
        <hr class="hr-2" [ngClass]="isAnagrafeCollapsed ? 'mb-3' : ''" />
        <div [ngbCollapse]="isAnagrafeCollapsed" class="dont-collapse-xl">
          <ul class="sidebar-menu">
            <li class="sidebar-item even" routerLinkActive="active">
              <a role="button" (click)="accediASceltaMedico()">
                <i
                  class="bi bi-person-plus icona-sm"
                  [title]="isExpanded ? 'Accedi a scelta medico' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Accedi a scelta medico"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                ></i>
                <span class="text-item">Accedi a scelta medico</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="sidebar-item even notExpandedRounded my-3"
        routerLinkActive="active"
        ngbDropdown
        container="body"
        placement="end-top"
        *ngIf="!isExpanded"
      >
        <a
          role="button"
          ngbDropdownToggle
          placement="end"
          container="body"
          ngbTooltip="Anagrafe"
          tooltipClass="tooltip-sidebar"
          [disableTooltip]="isExpanded"
        >
          <i class="bi bi-person-plus icona-sm"></i>
        </a>
        <div ngbDropdownMenu class="drop-menu">
          <a routerLink="/home" [queryParams]="{ ricerca: '', categoria: 'anagrafe' }" role="button">
            <button ngbDropdownItem class="drop-item text-primary fw-bolder">Anagrafe</button>
          </a>
          <hr class="my-1" />
          <a (click)="accediASceltaMedico()" role="button">
            <button ngbDropdownItem class="drop-item text-primary">Accedi a scelta medico</button>
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
